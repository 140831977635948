import { ACHIEVEMENTS_TO_DISPLAY } from '@/components/platform/UserProfile/constants';
import { type UserProfileAchievementFragment } from '@/components/platform/UserProfile/graphql/generated/profileAchievement.generated';
import { type ProfileFullAchievementFragment } from '@/components/platform/UserProfile/graphql/generated/profileFullAchievement.generated';
import { type UserProfileUserAchievementFragment } from '@/components/platform/UserProfile/graphql/generated/profileUserAchievement.generated';
import { AchievementRarity } from '@/controllers/graphql/generated';

type AchievementToDisplay = {
  achievement: UserProfileAchievementFragment & {
    userAchievement: UserProfileUserAchievementFragment;
  };
};

export interface UseAchievementsToDisplayResult {
  achievementsToProcess: AchievementToDisplay[];
  hasMore: boolean;
}

interface Options {
  achievements: ProfileFullAchievementFragment[];
  shouldShowAllAchievements?: boolean;
}

export const useAchievements = ({
  achievements,
  shouldShowAllAchievements = false,
}: Options): UseAchievementsToDisplayResult => {
  let achievementsToProcess: AchievementToDisplay[] = [];

  const userHasAchievementsUnlocked = achievements.length > 0;

  if (userHasAchievementsUnlocked) {
    achievementsToProcess = achievements
      .map((userAchievement) => ({
        achievement: {
          ...userAchievement.achievement,
          userAchievement,
        },
      }));
  }

  achievementsToProcess = achievementsToProcess.sort(
    (a, b) => {
      if (a.achievement.rarity === b.achievement.rarity) {
        return 0;
      }

      if (b.achievement.rarity === AchievementRarity.Rare) {
        return 1;
      }

      return -1;
    },
  );

  return {
    achievementsToProcess: shouldShowAllAchievements
      ? achievementsToProcess
      : achievementsToProcess.slice(0, ACHIEVEMENTS_TO_DISPLAY),
    hasMore: achievementsToProcess.length > ACHIEVEMENTS_TO_DISPLAY,
  };
};
