import { type SubscriptionProductFeaturesOptions, useSubscriptionProductFeatures } from '@/components/platform/SubscriptionProduct/hooks/useSubscriptionProductFeatures';
import { useActiveSubscription } from '@/controllers/subscriptions/subscriptions.hooks/useActiveSubscription';

export const useIsSubscriptionLimitationEnabled = (
  options?: SubscriptionProductFeaturesOptions,
) => {
  const { isAvailable } = useSubscriptionProductFeatures(options);

  const activeSubscription = useActiveSubscription({
    skip: !isAvailable,
  });

  return isAvailable && !activeSubscription;
};
