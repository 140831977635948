import { useCallback } from 'react';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import {
  useEnsureDirectChatMutation,
} from '@/components/platform/UserProfile/graphql/generated/ensureDirectChat.mutation.generated';
import { useShowErrorMessage } from '@/hooks/useShowErrorMessage';
import i18Next from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { truthy } from '@/lib/helpers/functional';
import { analyticsSDK } from '@/controllers/analytics';
import { type GoToDirectChatSource } from '@/controllers/analytics/generated';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';

interface UseGoToDirectChatResult {
  isLoading: boolean;
  goToDirectChat: () => Promise<void>;
}

export const useGoToDirectChat = (
  chatBuddyId: number,
  source: GoToDirectChatSource,
): UseGoToDirectChatResult => {
  const {
    push,
    pathname,
  } = i18Next.useRouter();
  const {
    openPaywall,
    shouldUseDMsPaywall,
  } = useUserProfileContext();
  const [authUser] = useAuthUser({ ssr: true });
  const userIds = [authUser?.id, chatBuddyId]
    .filter(truthy);

  const [
    ensureDirectChat,
    {
      loading,
      error,
    },
  ] = useEnsureDirectChatMutation({
    variables: {
      userIds,
    },
  });

  useShowErrorMessage(error);

  const goToDirectChat = useCallback(async () => {
    if (shouldUseDMsPaywall) {
      openPaywall();

      return;
    }

    const { data } = await ensureDirectChat();

    analyticsSDK.chat.sendGoToDirectChatEvent({
      source,
      pageURL: pathname,
    });

    if (!data) {
      return;
    }

    const {
      id,
    } = data.ensureDirectChat;

    push(ROUTES.chat(id).index);
  }, [
    ensureDirectChat,
    push,
    pathname,
    source,
    shouldUseDMsPaywall,
    openPaywall,
  ]);

  return {
    isLoading: loading,
    goToDirectChat,
  };
};
