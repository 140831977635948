import { memo } from 'react';
import {
  type PlatformStatisticItem,
} from '@/components/ui/PlatformStatistic/PlatformStatistic.typedefs';
import {
  IconWithBackground,
} from '@/components/ui/IconWithBackground/IconWithBackground';
import { cn } from '@/lib';
import { Selectors } from '@/lib/selectors';
import styles from './StatisticItem.module.scss';

type Props = {
  item: PlatformStatisticItem;
};

export const StatisticItem = memo<Props>(({ item }) => {
  const {
    icon,
    color,
    statistic,
    description,
    dataQa = 'statistic',
  } = item;

  return (
    <li className={styles.block}>
      <IconWithBackground iconElement={icon} color={color} />

      <div className={styles.titleWrapper}>
        <h4
          className={cn(styles.blockTitle, {
            [Selectors.Disabled]: Number(statistic) === 0,
          })}
          data-qa={dataQa}
        >
          {statistic}
        </h4>

        <p
          className={cn(styles.description, {
            [Selectors.Disabled]: Number(statistic) === 0,
          })}
        >
          {description}
        </p>
      </div>
    </li>
  );
});
