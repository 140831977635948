import React from 'react';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { IconEmojiNerdFace } from '@/components/ui/IconEmojies/IconEmojiNerdFace';
import styles from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/icons/LeaderboardLeagueIcon.module.scss';

export const IconLeaderboardNewbieLeague: FCIcon = (props) => (
  <div className={styles.container}>
    <BaseIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <circle
        cx="8.00025"
        cy="7.99683"
        r="7.26748"
        fill="#FFC692"
        stroke="#FF8855"
        strokeWidth="1"
      />
      <path
        d="M2.19554 4.65945C1.75715 5.42172 1.47318 6.26286 1.35987 7.13487C1.24655 8.00687 1.3061 8.89266 1.53511 9.74165C1.76412 10.5906 2.15811 11.3862 2.69458 12.0829C3.23105 12.7797 3.8995 13.3639 4.66177 13.8023C5.42403 14.2407 6.26518 14.5247 7.13718 14.638C8.00919 14.7513 8.89498 14.6918 9.74397 14.4627C10.593 14.2337 11.3885 13.8397 12.0853 13.3033C12.782 12.7668 13.3662 12.0983 13.8046 11.3361L8.00008 7.99777L2.19554 4.65945Z"
        fill="#FFD8B4"
      />
    </BaseIcon>

    <IconEmojiNerdFace
      className={styles.image}
      size={24}
    />
  </div>
);
