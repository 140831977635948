import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { type FCIcon } from './typedefs';

export const IconBadgeProfessionFrontend: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path d="M6.16667 6.16667V8.25C6.16667 8.48012 5.98012 8.66667 5.75 8.66667H5.33333V9.5H5.75C6.44036 9.5 7 8.94036 7 8.25V6.16667H6.16667Z" fill={BADGE_ICON_COLOR} />
    <path d="M8.45833 6.16667C7.88304 6.16667 7.41667 6.63304 7.41667 7.20833C7.41667 7.78363 7.88304 8.25 8.45833 8.25C8.57339 8.25 8.66667 8.34327 8.66667 8.45833C8.66667 8.57339 8.57339 8.66667 8.45833 8.66667H7.625V9.5H8.45833C9.03363 9.5 9.5 9.03363 9.5 8.45833C9.5 7.88304 9.03363 7.41667 8.45833 7.41667C8.34327 7.41667 8.25 7.32339 8.25 7.20833C8.25 7.09327 8.34327 7 8.45833 7H9.29167V6.16667H8.45833Z" fill={BADGE_ICON_COLOR} />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3333 3.25H3.66667C3.55616 3.25 3.45018 3.2939 3.37204 3.37204C3.2939 3.45018 3.25 3.55616 3.25 3.66667V10.3333C3.25 10.4438 3.2939 10.5498 3.37204 10.628C3.45018 10.7061 3.55616 10.75 3.66667 10.75H10.3333C10.4438 10.75 10.5498 10.7061 10.628 10.628C10.7061 10.5498 10.75 10.4438 10.75 10.3333V3.66667C10.75 3.55616 10.7061 3.45018 10.628 3.37204C10.5498 3.2939 10.4438 3.25 10.3333 3.25ZM4.08333 9.91667V4.08333H9.91667V9.91667H4.08333Z" fill={BADGE_ICON_COLOR} />
  </BaseIcon>
);
