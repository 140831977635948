import { type AdminProfileFragment } from '@/components/platform/UserProfile/graphql/generated/adminProfile.generated';
import { type PrivateUserProfileFragment } from '@/components/platform/UserProfile/graphql/generated/privateProfile.generated';
import { type PublicUserProfileFragment } from '@/components/platform/UserProfile/graphql/generated/publicProfile.generated';

interface UseProfile {
  (profile?: PublicUserProfileFragment
    | PrivateUserProfileFragment
    | AdminProfileFragment
    | null
  ): {
    profile: Omit<PublicUserProfileFragment, '__typename'> | null;
    isPrivate: boolean;
    isAdminProfile: boolean;
  };
}

export const useProfile: UseProfile = (profile) => {
  const fullProfile = {
    ...profile,
    id: profile?.id || 0,
    username: profile?.username || '',
  };

  if (!profile) {
    return {
      profile: null,
      isPrivate: false,
      isAdminProfile: false,
    };
  }

  return {
    profile: fullProfile,
    isPrivate: profile.__typename === 'PrivateUserProfile',
    isAdminProfile: profile.__typename === 'AdminProfile',
  };
};
