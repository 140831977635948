import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconLinkedIn: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20.9955 19.5V19.4993H21V12.8978C21 9.66826 20.3048 7.18052 16.5293 7.18052C14.7143 7.18052 13.4963 8.17652 12.999 9.12077H12.9465V7.48202H9.36675V19.4993H13.0942V13.5488C13.0942 11.982 13.3912 10.467 15.3315 10.467C17.2432 10.467 17.2718 12.255 17.2718 13.6493V19.5H20.9955Z" fill="currentColor" />
    <path d="M3.297 7.48274H7.029V19.5H3.297V7.48274Z" fill="currentColor" />
    <path d="M5.1615 1.5C3.96825 1.5 3 2.46825 3 3.6615C3 4.85475 3.96825 5.84325 5.1615 5.84325C6.35475 5.84325 7.323 4.85475 7.323 3.6615C7.32225 2.46825 6.354 1.5 5.1615 1.5Z" fill="currentColor" />
  </BaseIcon>
);
