import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconJobChange: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6H20C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19L2.01 8C2.01 6.89 2.89 6 4 6H8V4C8 2.89 8.89 2 10 2H14C15.11 2 16 2.89 16 4V6ZM10 6H14V4H10V6ZM12 7.00001C10.4393 6.99783 8.93041 7.55943 7.75095 8.58145L8.399 9.7482C9.13445 9.04224 10.0612 8.56802 11.064 8.38452C12.0668 8.20101 13.1014 8.31631 14.0392 8.71609C14.977 9.11586 15.7766 9.78246 16.3385 10.633C16.9005 11.4836 17.2001 12.4806 17.2 13.5H15.25L17.3235 17.231C18.0645 16.1754 18.5 14.8884 18.5 13.5C18.5 9.91005 15.5899 7.00001 12 7.00001ZM12 20C13.5607 20.0022 15.0696 19.4406 16.2491 18.4185L15.601 17.2518C14.8656 17.9578 13.9388 18.432 12.936 18.6155C11.9332 18.799 10.8986 18.6837 9.96083 18.2839C9.02304 17.8841 8.22345 17.2175 7.66147 16.367C7.09949 15.5164 6.79992 14.5194 6.8 13.5H8.75L6.6765 9.769C5.9355 10.8246 5.5 12.1116 5.5 13.5C5.5 17.0899 8.41005 20 12 20Z"
      fill="currentColor"
    />
  </BaseIcon>
);
