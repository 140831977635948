import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { type FCIcon } from './typedefs';

export const IconBadgeProfessionJava: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.00001 4.50001H6.16667V2.83334H7.00001V4.50001ZM5.33334 4.50001V3.66668H4.50001V4.50001H5.33334ZM8.66667 5.75001V9.08334C8.66667 9.30436 8.57887 9.51632 8.42259 9.6726C8.26631 9.82888 8.05435 9.91668 7.83334 9.91668H5.33334C5.11232 9.91668 4.90036 9.82888 4.74408 9.6726C4.5878 9.51632 4.50001 9.30436 4.50001 9.08334V5.75001H8.66667ZM10.3333 4.91668H4.08334C3.97283 4.91668 3.86685 4.96058 3.78871 5.03872C3.71057 5.11686 3.66667 5.22284 3.66667 5.33334V9.08334C3.66667 9.52537 3.84227 9.94929 4.15483 10.2619C4.46739 10.5744 4.89131 10.75 5.33334 10.75H7.83334C8.27537 10.75 8.69929 10.5744 9.01185 10.2619C9.32441 9.94929 9.50001 9.52537 9.50001 9.08334V7.83334H10.3333C10.5544 7.83334 10.7663 7.74555 10.9226 7.58927C11.0789 7.43299 11.1667 7.22102 11.1667 7.00001V5.75001C11.1667 5.529 11.0789 5.31703 10.9226 5.16075C10.7663 5.00447 10.5544 4.91668 10.3333 4.91668ZM9.50001 7.00001V5.75001H10.3333V7.00001H9.50001ZM8.66667 4.50001V3.25001H7.83334V4.50001H8.66667Z" fill={BADGE_ICON_COLOR} />
  </BaseIcon>
);
