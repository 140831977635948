import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { type FCIcon } from './typedefs';

export const IconBadgeProfessionQa: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.08333 4.49998H8.66666C8.66666 3.57951 7.92047 2.83331 7 2.83331C6.07952 2.83331 5.33333 3.57951 5.33333 4.49998H4.91666C4.55896 4.49998 4.25389 4.72535 4.13572 5.04185L3.29491 4.51645L2.85331 5.22316L4.08333 5.99176L4.08333 7.41665H2.83333V8.24998H4.08333C4.08333 8.71472 4.19203 9.15408 4.38538 9.54401L3.06894 10.432L3.53493 11.1228L4.85847 10.2301C5.39127 10.8061 6.15352 11.1666 7 11.1666C7.84647 11.1666 8.60873 10.8061 9.14153 10.2301L10.4651 11.1228L10.9311 10.432L9.61461 9.54401C9.80797 9.15408 9.91666 8.71472 9.91666 8.24998H11.1667V7.41665H9.91666L9.91666 5.99176L11.1467 5.22316L10.7051 4.51645L9.86427 5.04184C9.7461 4.72535 9.44103 4.49998 9.08333 4.49998ZM7 3.66665C7.46023 3.66665 7.83333 4.03974 7.83333 4.49998H6.16666C6.16666 4.03974 6.53976 3.66665 7 3.66665ZM6.58333 5.33331H4.91666V8.24998C4.91666 9.25788 5.6324 10.0986 6.58333 10.2916V5.33331ZM7.41666 5.33331V10.2916C8.3676 10.0986 9.08333 9.25788 9.08333 8.24998V5.33331H7.41666Z" fill={BADGE_ICON_COLOR} />
  </BaseIcon>
);
