import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconDiamondWithBackground: FCIcon = (props) => {
  // Used to prevent id conflicts when using this component multiple times on the same page
  const clipId = `clip_${Math.floor(Math.random() * 10000)}`;

  return (
    <BaseIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="8"
        cy="7.99683"
        r="7.26748"
        fill="#DCEBF7"
        stroke="#4289C1"
        strokeWidth="1"
      />
      <path
        d="M2.1953 4.65945C1.7569 5.42172 1.47294 6.26286 1.35962 7.13487C1.24631 8.00687 1.30585 8.89266 1.53486 9.74165C1.76387 10.5906 2.15786 11.3862 2.69434 12.0829C3.23081 12.7797 3.89926 13.3639 4.66152 13.8023C5.42379 14.2407 6.26493 14.5247 7.13694 14.638C8.00894 14.7513 8.89473 14.6918 9.74372 14.4627C10.5927 14.2337 11.3883 13.8397 12.085 13.3033C12.7817 12.7668 13.366 12.0983 13.8044 11.3361L7.99984 7.99777L2.1953 4.65945Z"
        fill="#C8E5FB"
      />
      <g clipPath={`url(#{${clipId})`}>
        <path
          d="M6.88889 4.66797H5.55556L4 6.66797H6.22222L6.88889 4.66797Z"
          fill="#BDDDF4"
        />
        <path
          d="M12 6.66797L10.4444 4.66797H9.11108L9.77775 6.66797H12Z"
          fill="#5DADEC"
        />
        <path
          d="M9.77778 6.66797H12L8 11.3346L9.77778 6.66797Z"
          fill="#4289C1"
        />
        <path
          d="M6.22222 6.66797H4L8 11.3346L6.22222 6.66797ZM6.88889 4.66797L6.22222 6.66797H9.77778L9.11111 4.66797H6.88889Z"
          fill="#8CCAF7"
        />
        <path
          d="M7.99995 11.3346L6.22217 6.66797H9.77772L7.99995 11.3346Z"
          fill="#5DADEC"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect
            width="8"
            height="8"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};
