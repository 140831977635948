import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconGhost: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11V18.5C21.0004 19.2479 20.7611 19.9763 20.3173 20.5784C19.8735 21.1804 19.2486 21.6245 18.534 21.8455C17.8194 22.0664 17.0529 22.0527 16.3467 21.8062C15.6406 21.5598 15.0319 21.0936 14.61 20.476C14.3488 20.9387 13.9693 21.3236 13.5105 21.5915C13.0516 21.8594 12.5298 22.0005 11.9985 22.0005C11.4672 22.0005 10.9454 21.8594 10.4865 21.5915C10.0277 21.3236 9.64823 20.9387 9.387 20.476C8.97402 21.0788 8.3827 21.5373 7.69598 21.7871C7.00927 22.0369 6.26158 22.0655 5.5578 21.8689C4.85401 21.6722 4.22941 21.2603 3.7716 20.6907C3.31379 20.1212 3.04572 19.4226 3.005 18.693L3 18.499V11C3 8.61305 3.94821 6.32387 5.63604 4.63604C7.32387 2.94821 9.61305 2 12 2ZM12 12C10.895 12 10 13.12 10 14.5C10 15.88 10.895 17 12 17C13.105 17 14 15.88 14 14.5C14 13.12 13.105 12 12 12ZM9.5 8C9.10218 8 8.72064 8.15804 8.43934 8.43934C8.15804 8.72064 8 9.10218 8 9.5C8 9.89782 8.15804 10.2794 8.43934 10.5607C8.72064 10.842 9.10218 11 9.5 11C9.89782 11 10.2794 10.842 10.5607 10.5607C10.842 10.2794 11 9.89782 11 9.5C11 9.10218 10.842 8.72064 10.5607 8.43934C10.2794 8.15804 9.89782 8 9.5 8ZM14.5 8C14.1022 8 13.7206 8.15804 13.4393 8.43934C13.158 8.72064 13 9.10218 13 9.5C13 9.89782 13.158 10.2794 13.4393 10.5607C13.7206 10.842 14.1022 11 14.5 11C14.8978 11 15.2794 10.842 15.5607 10.5607C15.842 10.2794 16 9.89782 16 9.5C16 9.10218 15.842 8.72064 15.5607 8.43934C15.2794 8.15804 14.8978 8 14.5 8Z" fill="currentColor" />
  </BaseIcon>
);
