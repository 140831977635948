import React, { type FC, useCallback } from 'react';
import { analyticsSDK } from '@/controllers/analytics';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { type UserProfileCityFragment } from '@/components/platform/UserProfile/graphql/generated/profileCity.generated';
import { type UserProfileStudentsGroupFragment } from '@/components/platform/UserProfile/graphql/generated/profileStudentsGroup.generated';
import { useMainInfoList } from '@/components/platform/UserProfile/UserProfileMainInfo/useMainInfoList';
import { DirectMessage } from '@/components/platform/UserProfile/UserProfileMainInfo/DirectMessage';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { Button } from '@/components/ui/Button';
import { ROUTES } from '@/controllers/router/router.contants';
import { Status } from '@/components/platform/UserProfile/UserProfileMainInfo/Status';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './UserProfileMainInfo.module.scss';

interface Props {
  userId?: number | null;
  username?: string | null;
  email?: string | null;
  city?: UserProfileCityFragment | null;
  statusText?: string | null;
  isPrivate?: boolean | null;
  isAdminProfile?: boolean;
  studentsGroup?: UserProfileStudentsGroupFragment | null;
}

export const UserProfileMainInfo: FC<Props> = React.memo(({
  username,
  city,
  statusText,
  studentsGroup,
  userId,
  isPrivate,
  isAdminProfile,
  email,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const [authUser] = useAuthUser({
    ssr: true,
  });

  const sendProfileEditAnalytics = useCallback(() => {
    analyticsSDK.userProfile.sendEditClickedEvent({});
  }, []);

  const infoItemsList = useMainInfoList({
    city,
    studentsGroup,
    isAdminProfile,
    email,
  });

  const isOwnProfile = authUser?.id === userId;

  const shouldShowStatus = (isOwnProfile || statusText) && !isPrivate;

  return (
    <div className={styles.wrapper}>
      <div className={styles.usernameWrapper}>
        <h2 className={cn(typography.platformH2, styles.userName)} data-qa='user-name'>{username}</h2>

        {isOwnProfile && (
          <Button
            href={ROUTES.user.profile}
            onClick={sendProfileEditAnalytics}
            size={Button.size.Small}
            mode={Button.mode.Secondary}
            text={t(`${I18N_CODES.common}:user_profile.edit_profile_button`)}
            data-qa='edit-profile-button'
          />
        )}
      </div>

      {(infoItemsList.length > 0) && (
        <ul className={cn(styles.list, typography.platformTextSecondary)}>
          {
            infoItemsList.map(({ id, renderIcon, title }) => (
              <li
                key={id}
                className={styles.listItem}
                data-qa={UserProfileHarnessSelectors.MainInfoBlock}
              >
                {renderIcon()}
                <span>{title}</span>
              </li>
            ))
          }
        </ul>
      )}

      {shouldShowStatus && (
        <div className={styles.statusWrapper}>
          <Status
            statusText={statusText}
            isOwnProfile={isOwnProfile}
            isAdminProfile={isAdminProfile}
            userId={userId}
          />
        </div>
      )}

      <div className='mt-12'>
        <DirectMessage />
      </div>
    </div>
  );
});
