import {
  type DetailedHTMLProps,
  type RefObject,
  type TextareaHTMLAttributes,
  memo,
} from 'react';
import { cn } from '@/lib/classNames';
import FormStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import TextAreaStyles from '@/components/ui/FormElements/FormInputs/InputTextArea/InputTextArea.module.scss';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';

type Props = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  textAreaRef?: RefObject<HTMLTextAreaElement>;
  dataQa?: string;
};

export const InputTextAreaUI = memo<Props>((
  props,
) => {
  const {
    className,
    rows = 4,
    textAreaRef,
    dataQa,
    ...rest
  } = props;

  return (
    <textarea
      data-qa={dataQa}
      className={cn(className, FormStyles.input, TextAreaStyles.textArea)}
      rows={rows}
      ref={textAreaRef}
      {...rest}
    />
  );
});

export const InputTextArea = withFinalFormController<string>()<Props>(
  InputTextAreaUI,
);
