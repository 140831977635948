import React, { type FC, useCallback } from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { ICON_SIZE, SHADOW_PROPS } from '@/components/platform/UserProfile/AchievementsBlock/constants';
import { type ProfileFullAchievementFragment } from '@/components/platform/UserProfile/graphql/generated/profileFullAchievement.generated';
import { useAchievements } from '@/components/platform/UserProfile/AchievementsBlock/hooks/useAchievements';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import { AchievementItem } from '@/components/platform/Achievements';
import { Button } from '@/components/ui/Button';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';
import { UserProfileMode } from '@/components/platform/UserProfile/typedefs';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { AchievementsEvents } from '@/controllers/achievements/achievements.events';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import styles from './Achievements.module.scss';

interface Props {
  achievements?: ProfileFullAchievementFragment[] | null;
}

export const Achievements: FC<Props> = React.memo(({
  achievements,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const [authUser] = useAuthUser({
    ssr: true,
  });
  const { setMode, userId } = useUserProfileContext();
  const {
    achievementsToProcess,
    hasMore,
  } = useAchievements({
    achievements: achievements ?? [],
  });

  const hasMoreAchievementsSign = (
    hasMore
      ? '+'
      : ''
  );

  const loadFullAchievementsList = useCallback(
    () => {
      const isOtherUserAchievements = authUser && authUser?.id !== userId;
      const analyticOptions = isOtherUserAchievements
        ? {
          isOtherUserAchievements,
        }
        : {};

      AchievementsEvents.sendEvent(
        AchievementsEvents.events.blockShowAllClicked,
        analyticOptions,
      );
      setMode(UserProfileMode.FullAchievementsListView);
    },
    [setMode, authUser, userId],
  );

  if (!achievementsToProcess || !achievementsToProcess.length) {
    return null;
  }

  return (
    <div
      data-qa={UserProfileHarnessSelectors.AchievementsBlock}
    >
      <div className={styles.titleWrapper}>
        <h3 className={cn(typography.platformH3, styles.title)} data-qa='amount-of-achievements'>
          {t(`${I18N_CODES.common}:user_profile.achievements_block_title`)}
          {' '}
          <span className={cn(
            typography.platformTextSecondary,
            styles.titleCount,
          )}
          >
            {`(${achievementsToProcess?.length}${hasMoreAchievementsSign})`}
          </span>
        </h3>

        <Button
          className={styles.viewAllButton}
          mode={Button.mode.TransparentLight}
          size={Button.size.Small}
          text={t(`${I18N_CODES.common}:user_profile.achievements_block_view_all_button`)}
          data-qa={UserProfileHarnessSelectors.ViewAllAchievementsButton}
          onClick={loadFullAchievementsList}
        />
      </div>

      <div className={cn(styles.achievementsWrapper, 'mt-12')}>
        {achievementsToProcess.map(({ achievement }, i) => (
          <AchievementItem
            key={achievement.id || i}
            data-qa={UserProfileHarnessSelectors.AchievementItem}
            className={styles.achievementItem}
            achievement={achievement}
            userAchievement={achievement.userAchievement}
            showName={false}
            showNewBadge={false}
            showCategory={false}
            iconWidth={ICON_SIZE}
            iconHeight={ICON_SIZE}
            shadowProps={SHADOW_PROPS}
          />
        ))}
      </div>
    </div>
  );
});
