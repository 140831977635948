import React, { type FC, useCallback, useMemo } from 'react';
import { UserAvatarBadgeType } from '@/controllers/graphql/generated';
import { Image } from '@/components/ui/Image';
import { UserAvatarFallback } from '@/components/ui/UserAvatar/components/UserAvatarFallback';
import { cn } from '@/lib';
import { UserAvatarBadge } from '@/components/ui/UserAvatar/components/UserAvatarBadge/UserAvatarBadge';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';
import { useIsOnline } from '@/components/platform/UserProfile/hooks/useIsOnline';
import { UserAvatarHarnessSelectors } from '@/components/ui/UserAvatar/UserAvatar.harness';
import { exists } from '@/lib/helpers/functional';
import { UserAvatarBadgePosition } from '@/components/ui/UserAvatar/components/UserAvatarBadge/UserAvatarBadge.typedefs';
import { type ProfileOpenerProps, UserProfileOpener } from '@/components/platform/UserProfile/typedefs';
import { type ImageData } from '@/components/ui/Image/typedefs';
import styles from './UserAvatar.module.scss';

export interface AvatarFile {
  url: string;
  imageData?: ImageData;
}

export interface UserAvatarProps {
  fullName: string;
  userId: number;
  avatar?: AvatarFile | null;
  size?: number;
  badgeType?: UserAvatarBadgeType | null;
  badgePosition?: UserAvatarBadgePosition;
  shouldShowBadgeIcon?: boolean;
  isInactive?: boolean;
  whitePaddingSize?: number;
  lastActionTime?: Date | null;
  shouldShowOnlineIndicator?: boolean;
  onlineIndicatorSize?: number;
  wrapperClassName?: string;
}

export const UserAvatar: FC<ProfileOpenerProps<UserAvatarProps>> = ({
  fullName,
  avatar,
  size = 32,
  userId,
  badgeType,
  badgePosition = UserAvatarBadgePosition.BottomRight,
  shouldShowBadgeIcon = true,
  isInactive = false,
  shouldOpenProfile = true,
  userProfileOpener,
  whitePaddingSize = 0,
  lastActionTime,
  shouldShowOnlineIndicator = false,
  onlineIndicatorSize = 12,
  wrapperClassName,
}) => {
  const { openProfile } = useUserProfileContext();

  const openUserProfile = useCallback(() => {
    if (shouldOpenProfile && Boolean(userId)) {
      openProfile({
        userId,
        opener: userProfileOpener || UserProfileOpener.Unspecified,
      });
    }
  }, [
    shouldOpenProfile,
    openProfile,
    userId,
    userProfileOpener,
  ]);

  const angle = useMemo(() => {
    switch (badgePosition) {
      case UserAvatarBadgePosition.Left: {
        return -180;
      }

      case UserAvatarBadgePosition.BottomRight:
      default: {
        return 45;
      }
    }
  }, [badgePosition]);

  const { x, y } = useMemo(() => {
    const r = size / 2;

    return {
      x: r * Math.cos(angle * (Math.PI / 180)),
      y: r * Math.sin(angle * (Math.PI / 180)),
    };
  }, [angle, size]);

  const shouldShowBadge = useMemo(() => (
    shouldShowBadgeIcon
    && Boolean(badgeType)
  ), [
    badgeType,
    shouldShowBadgeIcon,
  ]);

  const sizeWithWhitePadding = size + whitePaddingSize;

  const isOnline = useIsOnline(lastActionTime);
  const isOnlineIndicatorVisible = isOnline && shouldShowOnlineIndicator;

  return (
    <div
      aria-hidden
      onClick={openUserProfile}
      className={cn(
        styles.avatarWrapper,
        exists(badgeType)
        && badgeType !== UserAvatarBadgeType.RoleRecruiter
        && styles[`type-${badgeType}`],
        wrapperClassName,
        {
          [styles.avatarWrapperWithBadge]: shouldShowBadge,
          [styles.interactiveAvatar]: shouldOpenProfile,
          [styles.avatarWrapperWithWhitePadding]: Boolean(whitePaddingSize),
          [styles.avatarWrapperWithOnlineIndicator]: isOnlineIndicatorVisible,
        },
      )}
      data-qa={UserAvatarHarnessSelectors.DataQa}
      style={{
        '--size': `${sizeWithWhitePadding}px`,
        '--badge-x': `${x}px`,
        '--badge-y': `${y}px`,
        '--white-padding-size': whitePaddingSize,
        '--online-indicator-size': onlineIndicatorSize,
      } as React.CSSProperties}
    >
      {shouldShowBadge && badgeType && (
        <UserAvatarBadge
          type={badgeType}
          className={styles.badge}
        />
      )}

      {avatar
        ? (
          <Image
            src={avatar.url}
            imageData={avatar.imageData}
            width={sizeWithWhitePadding}
            height={sizeWithWhitePadding}
            alt={fullName}
            objectFit="cover"
            className={styles.avatarImage}
            data-qa={UserAvatarHarnessSelectors.Image}
          />
        )
        : (
          <UserAvatarFallback
            userId={userId}
            fullName={fullName}
            isInactive={isInactive}
          />
        )}
    </div>
  );
};
