import React, { type FC } from 'react';
import { type File } from '@/controllers/graphql/generated';
import { UserAvatar } from '@/components/ui/UserAvatar';
import styles from './UserProfileHeader.module.scss';

interface Props {
  userId?: number | null;
  avatar?: File | null;
  username?: string | null;
  lastActionTime?: Date | null;
}

export const UserProfileHeader: FC<Props> = React.memo(({
  userId, avatar, username, lastActionTime,
}) => {
  if (!userId) {
    return null;
  }

  return (
    <div className={styles.avatarWrapper} data-qa='user-profile-header'>
      <UserAvatar
        userId={userId}
        avatar={avatar}
        fullName={username ?? ''}
        size={96}
        shouldOpenProfile={false}
        whitePaddingSize={6}
        shouldShowOnlineIndicator
        lastActionTime={lastActionTime}
        onlineIndicatorSize={24}
      />
    </div>
  );
});
