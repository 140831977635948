import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconFireWithBackground: FCIcon = (props) => {
  // Used to prevent id conflicts when using this component multiple times on the same page
  const gradientId = `paint0_linear_6_${Math.floor(Math.random() * 10000)}`;

  return (
    <BaseIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="10"
        cy="10"
        r="8.5"
        fill="white"
        stroke="white"
      />
      <circle
        cx="10"
        cy="10"
        r="7.5"
        fill="#FF3500"
        fillOpacity="0.22"
        stroke="#E25544"
      />
      <path
        d="M3.93192 6.51087C3.47363 7.30773 3.17678 8.18706 3.05832 9.09865C2.93985 10.0102 3.0021 10.9362 3.24151 11.8238C3.48092 12.7113 3.89279 13.543 4.45361 14.2713C5.01444 14.9997 5.71323 15.6104 6.5101 16.0687C7.30696 16.527 8.18629 16.8239 9.09788 16.9423C10.0095 17.0608 10.9355 16.9986 11.823 16.7592C12.7105 16.5198 13.5422 16.1079 14.2706 15.5471C14.9989 14.9862 15.6097 14.2874 16.068 13.4906L9.99995 10.0007L3.93192 6.51087Z"
        fill="#FEE3DA"
      />
      <path
        d="M13.7418 10.4324C12.9566 8.39039 10.161 8.28028 10.8361 5.31233C10.8861 5.09211 10.6511 4.92195 10.461 5.03706C8.64561 6.10812 7.34031 8.25525 8.43556 11.068C8.52558 11.2983 8.25552 11.5135 8.06048 11.3633C7.15527 10.6777 7.06024 9.69168 7.14026 8.98598C7.17027 8.72572 6.83019 8.6006 6.68516 8.81581C6.34508 9.33633 6 10.1772 6 11.4434C6.19004 14.2462 8.55559 15.1071 9.40579 15.2172C10.6211 15.3723 11.9364 15.1471 12.8816 14.2812C13.9218 13.3153 14.3019 11.7737 13.7418 10.4324ZM9.10072 12.9499C9.82088 12.7747 10.191 12.2542 10.291 11.7938C10.456 11.0781 9.81088 10.3774 10.246 9.24624C10.411 10.1822 11.8814 10.7677 11.8814 11.7888C11.9214 13.055 10.5511 14.1411 9.10072 12.9499Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="14"
          y1="5"
          x2="6"
          y2="15.2626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7246" />
          <stop
            offset="1"
            stopColor="#D12B17"
          />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
};
