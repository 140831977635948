import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { type FCIcon } from './typedefs';

export const IconBadgeProfessionRecruitment: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path d="M7.00001 7.83335V8.66669C6.33696 8.66669 5.70108 8.93008 5.23224 9.39892C4.7634 9.86776 4.50001 10.5036 4.50001 11.1667H3.66667C3.66667 10.2826 4.01786 9.43478 4.64298 8.80966C5.2681 8.18454 6.11595 7.83335 7.00001 7.83335ZM7.00001 7.41669C5.61875 7.41669 4.50001 6.29794 4.50001 4.91669C4.50001 3.53544 5.61875 2.41669 7.00001 2.41669C8.38126 2.41669 9.5 3.53544 9.5 4.91669C9.5 6.29794 8.38126 7.41669 7.00001 7.41669ZM7.00001 6.58335C7.92084 6.58335 8.66667 5.83752 8.66667 4.91669C8.66667 3.99585 7.92084 3.25002 7.00001 3.25002C6.07917 3.25002 5.33334 3.99585 5.33334 4.91669C5.33334 5.83752 6.07917 6.58335 7.00001 6.58335ZM10.9358 10.3467L11.5625 10.9734L10.9733 11.5625L10.3467 10.9359C9.99723 11.1419 9.58464 11.2134 9.18624 11.137C8.78784 11.0606 8.43098 10.8415 8.18256 10.5208C7.93414 10.2001 7.81121 9.79982 7.83681 9.39497C7.86242 8.99012 8.0348 8.6085 8.32164 8.32166C8.60849 8.03481 8.99011 7.86243 9.39496 7.83683C9.79981 7.81122 10.2001 7.93415 10.5208 8.18257C10.8415 8.431 11.0606 8.78785 11.137 9.18625C11.2134 9.58466 11.1419 9.99725 10.9358 10.3467ZM9.5 10.3334C9.72102 10.3334 9.93298 10.2456 10.0893 10.0893C10.2455 9.93299 10.3333 9.72103 10.3333 9.50002C10.3333 9.27901 10.2455 9.06704 10.0893 8.91076C9.93298 8.75448 9.72102 8.66669 9.5 8.66669C9.27899 8.66669 9.06703 8.75448 8.91075 8.91076C8.75447 9.06704 8.66667 9.27901 8.66667 9.50002C8.66667 9.72103 8.75447 9.93299 8.91075 10.0893C9.06703 10.2456 9.27899 10.3334 9.5 10.3334Z" fill={BADGE_ICON_COLOR} />
  </BaseIcon>
);
