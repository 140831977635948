import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconLocationFill: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99967 1.33334C5.41967 1.33334 3.33301 3.42001 3.33301 6.00001C3.33301 9.50001 7.99967 14.6667 7.99967 14.6667C7.99967 14.6667 12.6663 9.50001 12.6663 6.00001C12.6663 3.42001 10.5797 1.33334 7.99967 1.33334ZM7.99967 7.66668C7.07967 7.66668 6.33301 6.92001 6.33301 6.00001C6.33301 5.08001 7.07967 4.33334 7.99967 4.33334C8.91967 4.33334 9.66634 5.08001 9.66634 6.00001C9.66634 6.92001 8.91967 7.66668 7.99967 7.66668Z"
      fill="currentColor"
    />
  </BaseIcon>
);
