import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { type FCIcon } from './typedefs';

export const IconBadgeProfessionPython: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path d="M5.33333 5.29545L5.33333 3.66667C5.33333 3.43655 5.51988 3.25 5.74999 3.25H8.24999C8.48011 3.25 8.66666 3.43655 8.66666 3.66667V6.58333C8.66666 6.81345 8.48011 7 8.24999 7H5.74999C5.51988 7 5.33333 7.18655 5.33333 7.41667L5.33333 10.3333C5.33333 10.5635 5.51988 10.75 5.74999 10.75H8.24999C8.48011 10.75 8.66666 10.5635 8.66666 10.3333V8.70455" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" />
    <path d="M7 5.75H3.66667C3.43655 5.75 3.25 5.93655 3.25 6.16667V8.66667C3.25 8.89679 3.43655 9.08333 3.66667 9.08333H5.29545" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" />
    <path d="M7 8.25H10.3333C10.5635 8.25 10.75 8.06345 10.75 7.83333V5.33333C10.75 5.10321 10.5635 4.91667 10.3333 4.91667H8.70455" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" />
    <rect x="6.37501" y="4.29165" width="0.416667" height="0.416667" rx="0.208333" fill={BADGE_ICON_COLOR} stroke={BADGE_ICON_COLOR} strokeWidth="0.416667" />
    <rect x="7.20833" y="9.29165" width="0.416667" height="0.416667" rx="0.208333" fill={BADGE_ICON_COLOR} stroke={BADGE_ICON_COLOR} strokeWidth="0.416667" />
  </BaseIcon>
);
