import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_ICON_COLOR,
  BADGE_BACKGROUND_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { type FCIcon } from './typedefs';

export const IconBadgeRoleRecruiter: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path d="M7.41667 2.41669L7.41709 3.69252C8.15134 3.78535 8.83386 4.11978 9.35715 4.64315C9.88045 5.16652 10.2148 5.84908 10.3075 6.58335H11.5833V7.41669L10.3075 7.4171C10.2147 8.1513 9.88032 8.83377 9.35704 9.35705C8.83375 9.88034 8.15128 10.2147 7.41709 10.3075L7.41667 11.5834H6.58334V10.3075C5.84907 10.2148 5.1665 9.88046 4.64314 9.35717C4.11977 8.83388 3.78533 8.15136 3.6925 7.4171L2.41667 7.41669V6.58335H3.6925C3.78525 5.84902 4.11964 5.16641 4.64302 4.64303C5.16639 4.11966 5.84901 3.78526 6.58334 3.69252V2.41669H7.41667ZM7 4.50002C6.33696 4.50002 5.70108 4.76341 5.23224 5.23225C4.7634 5.70109 4.50001 6.33698 4.5 7.00002C4.50001 7.66306 4.7634 8.29895 5.23224 8.76779C5.70108 9.23663 6.33696 9.50002 7 9.50002C7.66305 9.50002 8.29893 9.23663 8.76777 8.76779C9.23661 8.29895 9.5 7.66306 9.5 7.00002C9.5 6.33698 9.23661 5.70109 8.76777 5.23225C8.29893 4.76341 7.66305 4.50002 7 4.50002Z" fill={BADGE_ICON_COLOR} />
    <ellipse cx="7.00001" cy="5.75002" rx="0.833333" ry="0.833333" fill={BADGE_ICON_COLOR} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.33333 7.72866C5.70073 7.28812 6.31023 7 6.99999 7C7.68975 7 8.29925 7.28813 8.66666 7.72867C8.4146 8.27651 7.76349 8.66666 6.99999 8.66666C6.23649 8.66666 5.58538 8.27651 5.33333 7.72866Z" fill={BADGE_ICON_COLOR} />
  </BaseIcon>
);
