import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconGroup: FCIcon = (props) => (
  <BaseIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M -0.000244141 21 C -0.000244141 18.8783 0.842611 16.8434 2.3429 15.3431 C 3.84319 13.8429 5.87802 13 7.99976 13 C 10.1215 13 12.1563 13.8429 13.6566 15.3431 C 15.1569 16.8434 15.9998 18.8783 15.9998 21 H 13.9998 C 13.9998 19.4087 13.3676 17.8826 12.2424 16.7574 C 11.1172 15.6321 9.59105 15 7.99976 15 C 6.40846 15 4.88233 15.6321 3.75712 16.7574 C 2.6319 17.8826 1.99976 19.4087 1.99976 21 H -0.000244141 Z M 7.99976 12 C 4.68476 12 1.99976 9.315 1.99976 6 C 1.99976 2.685 4.68476 0 7.99976 0 C 11.3148 0 13.9998 2.685 13.9998 6 C 13.9998 9.315 11.3148 12 7.99976 12 Z M 7.99976 10 C 10.2098 10 11.9998 8.21 11.9998 6 C 11.9998 3.79 10.2098 2 7.99976 2 C 5.78976 2 3.99976 3.79 3.99976 6 C 3.99976 8.21 5.78976 10 7.99976 10 Z M 16.2838 13.703 C 17.6891 14.3359 18.8817 15.3612 19.7183 16.6557 C 20.5549 17.9502 20.9999 19.4587 20.9998 21 H 18.9998 C 19 19.844 18.6663 18.7125 18.0388 17.7416 C 17.4114 16.7707 16.5168 16.0017 15.4628 15.527 L 16.2828 13.703 H 16.2838 Z M 15.5958 2.413 C 16.6033 2.8283 17.4647 3.53354 18.0708 4.43923 C 18.6768 5.34492 19.0002 6.41024 18.9998 7.5 C 19.0002 8.87233 18.4875 10.1952 17.5623 11.2088 C 16.6371 12.2224 15.3664 12.8535 13.9998 12.978 V 10.965 C 14.7407 10.8589 15.4281 10.518 15.9611 9.99246 C 16.494 9.4669 16.8444 8.78432 16.9609 8.04493 C 17.0774 7.30555 16.9537 6.5483 16.6081 5.88435 C 16.2625 5.22041 15.7132 4.68475 15.0408 4.356 L 15.5958 2.413 Z"
      fill="currentColor"
    />
  </BaseIcon>
);
