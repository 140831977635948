import React from 'react';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/icons/LeaderboardLeagueIcon.module.scss';
import { IconEmojiFullMoonWithFace } from '@/components/ui/IconEmojies/IconEmojiFullMoonWithFace';

export const IconLeaderboardFulltimeLeague: FCIcon = (props) => (
  <div className={styles.container}>
    <BaseIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <circle
        cx="8"
        cy="7.99683"
        r="7.26748"
        fill="#FFEC92"
        stroke="#FEC132"
        strokeWidth="1"
      />
      <path
        d="M2.1953 4.65945C1.7569 5.42172 1.47294 6.26286 1.35962 7.13487C1.24631 8.00687 1.30585 8.89266 1.53486 9.74165C1.76387 10.5906 2.15786 11.3862 2.69434 12.0829C3.23081 12.7797 3.89926 13.3639 4.66152 13.8023C5.42379 14.2407 6.26493 14.5247 7.13694 14.638C8.00894 14.7513 8.89473 14.6918 9.74372 14.4627C10.5927 14.2337 11.3883 13.8397 12.085 13.3033C12.7817 12.7668 13.366 12.0983 13.8044 11.3361L7.99984 7.99777L2.1953 4.65945Z"
        fill="#FFFDB8"
      />
    </BaseIcon>

    <IconEmojiFullMoonWithFace
      className={styles.image}
      size={48}
    />
  </div>
);
