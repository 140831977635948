import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { type FCIcon } from './typedefs';

export const IconBadgeRoleSales: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path
      d="M7 11.1666C4.69875 11.1666 2.83333 9.30123 2.83333 6.99998C2.83333 4.69873 4.69875 2.83331 7 2.83331C9.30125 2.83331 11.1667 4.69873 11.1667 6.99998C11.1667 9.30123 9.30125 11.1666 7 11.1666ZM7 10.3333C7.88405 10.3333 8.7319 9.98212 9.35702 9.357C9.98214 8.73188 10.3333 7.88404 10.3333 6.99998C10.3333 6.11592 9.98214 5.26808 9.35702 4.64296C8.7319 4.01784 7.88405 3.66665 7 3.66665C6.11594 3.66665 5.26809 4.01784 4.64297 4.64296C4.01785 5.26808 3.66666 6.11592 3.66666 6.99998C3.66666 7.88404 4.01785 8.73188 4.64297 9.357C5.26809 9.98212 6.11594 10.3333 7 10.3333ZM5.54166 7.83331H7.83333C7.88858 7.83331 7.94157 7.81136 7.98064 7.77229C8.01971 7.73322 8.04166 7.68023 8.04166 7.62498C8.04166 7.56973 8.01971 7.51674 7.98064 7.47767C7.94157 7.4386 7.88858 7.41665 7.83333 7.41665H6.16666C5.89039 7.41665 5.62544 7.3069 5.43009 7.11155C5.23474 6.9162 5.12499 6.65125 5.12499 6.37498C5.12499 6.09871 5.23474 5.83376 5.43009 5.63841C5.62544 5.44306 5.89039 5.33331 6.16666 5.33331H6.58333V4.49998H7.41666V5.33331H8.45833V6.16665H6.16666C6.11141 6.16665 6.05842 6.1886 6.01935 6.22767C5.98028 6.26674 5.95833 6.31973 5.95833 6.37498C5.95833 6.43023 5.98028 6.48322 6.01935 6.52229C6.05842 6.56136 6.11141 6.58331 6.16666 6.58331H7.83333C8.1096 6.58331 8.37455 6.69306 8.5699 6.88841C8.76525 7.08376 8.875 7.34871 8.875 7.62498C8.875 7.90125 8.76525 8.1662 8.5699 8.36155C8.37455 8.5569 8.1096 8.66665 7.83333 8.66665H7.41666V9.49998H6.58333V8.66665H5.54166V7.83331Z"
      fill={BADGE_ICON_COLOR}
    />
  </BaseIcon>
);
