import React, { type FC, useMemo } from 'react';
import { IconStarFourPoints } from '@/components/ui/icons/IconStarFourPoints';
import { USER_PROFILE_LEAGUE_TYPE_CONTENT } from '@/components/platform/UserProfile/StatisticBlock/constants';
import {
  type UserProfileLeaderboardUserFragment,
} from '@/components/platform/UserProfile/graphql/generated/profileLeaderBoardUser.generated';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import { LeaderboardLeagueType } from '@/controllers/graphql/generated';
import { IconFire } from '@/components/ui/icons/IconFire';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  type PlatformStatisticItem,
} from '@/components/ui/PlatformStatistic/PlatformStatistic.typedefs';
import { PlatformStatistic } from '@/components/ui/PlatformStatistic';
import { IconWithBackgroundColor } from '@/components/ui/IconWithBackground/typedefs';

interface Props {
  leaderboardUser: UserProfileLeaderboardUserFragment;
}

export const Statistic: FC<Props> = React.memo(({
  leaderboardUser,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const currentLeagueType = leaderboardUser?.league.type;

  const {
    icon: leagueIcon,
    titleCode: leagueTitleCode,
  } = USER_PROFILE_LEAGUE_TYPE_CONTENT[
    currentLeagueType ?? LeaderboardLeagueType.Newbie
  ];

  const statisticItems: PlatformStatisticItem[] = useMemo(() => ([
    {
      id: 1,
      icon: <IconFire />,
      color: leaderboardUser.currentStreakActiveDaysCount > 0
        ? IconWithBackgroundColor.DarkGray
        : IconWithBackgroundColor.Red,
      statistic: String(leaderboardUser.currentStreakActiveDaysCount),
      description: t(`${I18N_CODES.common}:user_profile.streaks.streaks_count`, {
        count: leaderboardUser.currentStreakActiveDaysCount,
      }),
      dataQa: 'amount-of-streak-days',
    },
    {
      id: 2,
      icon: leagueIcon,
      statistic: t(`${I18N_CODES.common}:${leagueTitleCode}`),
      description: t(`${I18N_CODES.common}:user_profile.leaderboard.league_caption`),
      color: IconWithBackgroundColor.None,
      dataQa: 'current-leaderboard-league',
    },
    {
      id: 3,
      icon: <IconStarFourPoints />,
      statistic: String(leaderboardUser.totalXP),
      description: t(`${I18N_CODES.common}:user_profile.leaderboard.total_xp_caption`),
      color: leaderboardUser.totalXP === 0
        ? IconWithBackgroundColor.DarkGray
        : IconWithBackgroundColor.Green,
      dataQa: 'total-amount-of-xp',
    },
    {
      id: 4,
      icon: <IconStarFourPoints />,
      statistic: String(leaderboardUser.xp),
      description: t(`${I18N_CODES.common}:user_profile.leaderboard.month_xp_caption`),
      color: leaderboardUser.xp === 0
        ? IconWithBackgroundColor.DarkGray
        : IconWithBackgroundColor.Yellow,
      dataQa: 'month-amount-of-xp',
    },
  ]), [
    leaderboardUser.currentStreakActiveDaysCount,
    leaderboardUser.totalXP,
    leaderboardUser.xp,
    leagueIcon,
    leagueTitleCode,
    t,
  ]);

  return (
    <PlatformStatistic
      heading={t(`${I18N_CODES.common}:user_profile.statistic_block_title`)}
      statisticItems={statisticItems}
      dataQa={UserProfileHarnessSelectors.StatisticBlock}
    />
  );
});
