import { type UserProfileSocialLinksFragment } from '@/components/platform/UserProfile/graphql/generated/profileSocialLinks.generated';
import { type ClearSocialLinks } from '@/components/platform/UserProfile/SocialLinksBlock/typedefs';

interface UseClearLinks {
  (
    links?: UserProfileSocialLinksFragment | null
  ): [keyof ClearSocialLinks, string][];
}

export const useClearLinks: UseClearLinks = (
  links,
) => Object.entries(links ?? [])
  .filter(([key, value]) => value && key !== '__typename') as [keyof ClearSocialLinks, string][];
